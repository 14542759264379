<template>
    <div class="bg d-flex row justify-content-center align-items-center">
      <div class="">
        <h1 class="title title-1"><strong>Nos gustaria saber tu opinión y estamos a tus ordenes para ayudarte. </strong></h1>
      </div>
    </div>
        <div class="container-fluid pt-3">
          <div class="row justify-content-center pb-3">
              <div class="col-12 col-xl-9">
                  <div id="masInformacion" class="card">
                          <div class="card-body">
                              <div class="row align-items-center">
                                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
                                      <h2 class="pb-3"><strong>¿Necesitas más información?</strong></h2>
                                      <p class="card-text">
                                          Puedes completar el formulario que hemos puesto a tu disposición. Nos gustaría recibir tus comentarios y sugerencias para poder seguir mejorando y ofrecerte un servicio de calidad.
                                      </p>
                                  </div>
                                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 align">
                                      <button type="button" class="btn-orange mt-4 mb-3" @click="goTo('/','')">Solicitar préstamo</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        <div class="container-fluid pt-1">
          <div class="row justify-content-center pb-3">
              <div class="col-12 col-xl-9 ">
                  <div id="contacto" class="card">
                          <div class="card-body">
                              <div class="row align-items-start">
                                  <div id="contacta" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5">
                                      <h4 class="pb-4"><strong>Contáctanos</strong></h4>
                                      
                                      <div class="d-flex justify-content-center justify-content-xl-start align-items-center mb-3">
                                          <img width="18" height="18" class="mr-3" src="@/assets/img/phone-icon.svg" alt="Ibancar phone">
                                          <a href="tel:+525555661583" style="color: #004680"><strong>55 5566 1583</strong></a>
                                      </div>
                                      
                                      <div class="d-flex justify-content-center justify-content-xl-start align-items-center mb-3">
                                          <img width="18" height="18" class="mr-3" src="@/assets/img/clock-icon.svg" alt="Ibancar clock">
                                          <p class="mb-0">Lunes a Viernes de <strong>9:00h</strong> a <strong>18:00h</strong></p>
                                      </div>
                                      
                                      <div class="d-flex justify-content-center justify-content-xl-start align-items-center mb-3">
                                          <img width="18" height="18" class="mr-3" src="@/assets/img/mail-icon.svg" alt="Ibancar mail">
                                          <a href="mailto:hola@ibancar.mx" style="color: #004680"><strong>hola@ibancar.mx</strong></a>
                                      </div>

                                  </div>
                                  <div id="enviarMensaje" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7">
                                      <div v-if="!mostrarMensaje">
                                          <form id="contactar" @submit.prevent="onSubmit">
                                          <h4><strong>Enviar mensaje</strong></h4>
                                              <div class="form-group row pt-4">
                                                  <div class="col">
                                                  <input  id="nombre" type="text" class="form-control" placeholder="Nombre*" v-model="formulario.nombre" @blur="comprobarNombre()" >
                                                  <p v-if="errors.nombre" class="error">
                                                      Debe rellenar este campo...
                                                  </p>
                                                  </div>
                                              </div>
                                              <div class="form-group row pt-4">
                                                  <div class="col">
                                                  <input id="email" type="text" class="form-control" placeholder="Email*" v-model="formulario.email" @blur="comprobarEmail()">
                                                  <p v-if="errors.email" class="error">
                                                      Formato de email incorrecto
                                                  </p>
                                                  <p v-if="errors.emailVacio" class="error">
                                                      Debe rellenar este campo...
                                                  </p>
                                                  </div>
                                              </div>
                                              <div class="form-group row pt-4">
                                                  <div class="col">
                                                  <input id="telefono" type="tel" class="form-control" placeholder="Teléfono a 10 dígitos" maxlength="10" v-model="formulario.telefono" @blur="comprobarTelefono()">
                                                  <p v-if="errors.telefono" class="error">
                                                      Ingresa tu teléfono a 10 dígitos
                                                  </p>
                                                  <p v-if="errors.telefonoVacio" class="error">
                                                      Debe rellenar este campo...
                                                  </p>
                                                  </div>
                                              </div>
                                              <div class="form-group row pt-4">
                                                  <div class="col d-flex justify-content-center">
                                                      <div class="row">
                                                          <textarea id="observaciones" rows="10" maxlength="1000" v-model="formulario.observaciones" @blur="comprobarObservaciones()"></textarea>
                                                          <p v-if="errors.observaciones" class="error">
                                                              Debe rellenar este campo...
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="row align-items-center pt-4">
                                                  <div class="col text-center">
                                                      <div class="form-check mb-2">
                                                          <label class="checkbox_label" for="checkLegal">
                                                              <input type="checkbox" id="checkLegal" class="checkbox" name="lopd" v-model="formulario.checkLegal" @blur="comprobarChecks()" requerid>
                                                              <span class="checkbox_text">He leído y acepto el  <router-link to="/politica-privacidad" target="_blank">Aviso de Privacidad</router-link></span>
                                                          </label>
                                                          <p v-if="errors.checkLegal" class="error">
                                                              Debe marcar este campo...
                                                          </p>
                                                      </div>
                                                          <!-- Captcha -->
                                                          <input type="hidden" id="captcha" v-model="formulario.captcha" >
                                                          <div id="captchaWidget1" class="g-recaptcha"></div>
                                                          <!--  -->
                                                  </div>
                                              </div>
                                                  <div class="row pb-4">
                                                      <div class="col align">
                                                          <button class="btn-orange mt-4" type="submit" :disabled="buttonEnabled()" >Contactar</button>
                                                      </div>
                                                  </div>
                                              <div class="form-group row">
                                                  <div class="col text-justify">
                                                      <i>
                                                          <small>En virtud de lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de
                                                              los Particulares, el cliente cuyos datos figuran en el presente Formulario, consiente de forma
                                                              expresa a Ibancar Latam S.A. de C.V. el tratamiento de sus datos personales con objeto de
                                                              realizar facturación y promoción de los servicios comercializados con los mismos, así como la
                                                              autorización a la comunicación con aquellas entidades respecto de las cuales tuviera
                                                              concertado contrato de Ibancar Latam S.A. de C.V. para prestación y promoción de servicios.
                                                              Los datos se incluirán en una base de datos automatizada de Ibancar Latam S.A. de C.V. que
                                                              dispone de las medidas de seguridad necesarias para su confidencialidad. Usted podrá ejercitar
                                                              conforme a la ley sus derechos de acceso, rectificación, cancelación/supresión, oposición,
                                                              limitación o portabilidad, dirigiendo un escrito a Ibancar Latam S.A. de C.V.
                                                          </small>
                                                      </i>
                                                  </div>
                                              </div>
                                          </form>
                                      </div>
                                      <div v-if="mostrarMensaje" id="respuesta" class="d-flex flex-row justify-content-center" >
                                          <ul>
                                              <li class="pb-2"><h4><strong>Mensaje Enviado</strong></h4></li>
                                              <li><p class="card-text">¡Gracias por contactar con Ibancar!</p></li>
                                              <li><p class="card-text">¡Su mensaje se ha enviado correctamente!</p></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      <Slogan />
      <RecaptchaV3 />
</template>

<script>
import Slogan from '@/components/Slogan.vue';
import axios from 'axios';
import RecaptchaV3 from '@/components/RecaptchaV3.vue';

window.functionCaptcha = function(token){
  if (token) {
      window.$vm.data.formulario.captcha = true;
  }
}

window.expiredCaptcha = function() {
  window.$vm.data.formulario.captcha = false;
}


export default {
name: "ContactoS1",
components:{
    Slogan,
    RecaptchaV3
},
data() {
    return {
      mostrarMensaje: false,
      characters: 0,
      formulario: {
        nombre: '',
        email: '',
        telefono: '',
        observaciones: '',
        checkLegal: false,
        captcha: false,
      },
      errors:{
          nombre: false,
          email: false,
          telefono: false,
          emailVacio: false,
          telefonoVacio: false,
          observaciones: false,
          checkLegal: false
      }
    }
},
methods: {
  goTo(route,hash) {
      this.$router.push({path: route, hash: hash});
  },
  buttonEnabled() {
      let nombre = this.formulario.nombre;
      let email = this.formulario.email;
      let telefono = this.formulario.telefono;
      let checkLegal = this.formulario.checkLegal;
      let observaciones = this.formulario.observaciones;
      // let captcha = this.formulario.captcha;
      if (this.validateEmail(email) && this.validatePhone(telefono) && nombre != '' && observaciones != '' && checkLegal) {
          return false;
      }else{
          return true;
      }
  },
  validatePhone(phone){
      let pattern = /^\d{10}$/;
      if (pattern.test(phone)){
          return true;
      }
      return false;
  },
  validateEmail(mail) {
      var pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (pattern.test(mail)){
          return true;
      }else if (mail == "") {
          return false;
      }
      return false;
  },
  comprobarNombre(){
      if (this.formulario.nombre == '') {
          this.errors.nombre = true;
          document.getElementById('nombre').style.borderLeft = '7px solid #ff000080';
          return false;
      }else{
          this.errors.nombre = false;
          document.getElementById('nombre').style.borderLeft = '7px solid green';
          return true;
      }
  },
  comprobarObservaciones(){
      if (this.formulario.observaciones == '') {
          this.errors.observaciones = true;
          document.getElementById('observaciones').style.borderLeft = '7px solid #ff000080';
          return false;
      }else {
          this.errors.observaciones = false;
          document.getElementById('observaciones').style.borderLeft = '7px solid green';
          return true;
      }
  },
  comprobarChecks(){
      if (this.formulario.checkLegal == '') {
          this.errors.checkLegal = true;
          return false;
      }else {
          this.errors.checkLegal = false;
          return false;
      }
  },
  comprobarTelefono(){
      if(this.formulario.telefono == ""){
          this.errors.telefonoVacio = true;
          this.errors.telefono = false;
          document.getElementById('telefono').style.borderLeft = '7px solid #ff000080';
          return false;
      } else if (!this.validatePhone(this.formulario.telefono)) {
          this.errors.telefono = true;
          this.errors.telefonoVacio = false;
          document.getElementById('telefono').style.borderLeft = '7px solid #ff000080';
          return false;
      }else{
          this.errors.telefono = false;
          this.errors.telefonoVacio = false;
          document.getElementById('telefono').style.borderLeft = '7px solid green';            
          return true;
      }
  },
  comprobarEmail(){
      if(this.formulario.email == ""){
          this.errors.emailVacio = true;
          this.errors.email = false;
          document.getElementById('email').style.borderLeft = '7px solid #ff000080';
          return false;
      } else if (!this.validateEmail(this.formulario.email)) {
          this.errors.email = true;
          this.errors.emailVacio = false;
          document.getElementById('email').style.borderLeft = '7px solid #ff000080';
          return false;
      }else{
          this.errors.email = false;
          document.getElementById('email').style.borderLeft = '7px solid green';
          return true;
      }
  },
  onSubmit() {
      axios.post(process.env.VUE_APP_AXIOS_BASE_URL+'mexico/contactoWebMail',{
          data: this.formulario
          }).then(
          (resp) => {
              if (resp) {
                  this.formulario.nombre = '';
                  this.formulario.email = '';
                  this.formulario.telefono = '';
                  this.formulario.observaciones = '';
                  this.formulario.checkLegal = 'false';
                  this.formulario.captcha = 'false';
                  this.mostrarMensaje = true;
                  this.goTo('/contacto','');
              }
          }
      ).catch( (e) => alert('Error: '+e))
  }
},
};
</script>

<style scoped lang="scss">

.title {
width: 100%;
font-family: 'Montserrat';
font-display: swap;
font-style: normal;
line-height: auto;
text-align: center;
color: #FFFFFF;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
ul {
  li {
      list-style: none;
  }
}
.form-control {
  border: 0px solid #fff;
  border-bottom: 1px solid #d4d4d4;
}

#contacto {
  border-bottom: 0px solid #fff;
}
.card {
  background-color: none;
  height: auto;
  border: 0px solid #FFFFFF;
  border-bottom: 0.5px solid #d4d4d4;
}
.error {
  text-align: left;
}
      .checkbox_label{
          display: flex;
          align-items: center;
      }
          
      .checkbox{
          outline: 1px auto #B8D4F6;
          width: 30px;
          height: 30px;
          cursor: pointer;
      }

      .checkbox_text {
          cursor: pointer;
          font-size: 10px;
          text-align: left;
          line-height: 16px;
          display: inline-block;
          margin-left: 12px;
          width: calc(100% - 42px);
      }

// Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs, sm) {
  .bg {
      margin-top: 67px;
  }
  .title-1{
      font-size: 1.3rem;
      line-height: 40px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title-h2 {
      font-size: 18px;
      line-height: 33px;
  }
  .h4-title {
      font-size: 1.15rem;
      text-decoration: underline;
      font-weight: 600;
  }
  h4 {
      &.text-orange {
          text-align: center;
      }
  }
  textarea {
      border: 1px solid #d4d4d4;
      outline: none;
      width: 82vw;
  }
  #masInformacion {
      text-align: center;
      button {
          text-align: center;
      }
  }
  #enviarMensaje {
      text-align: center;
      padding-top: 3rem;
  }
  #contacta {
      text-align: center;
  }
  small {
      font-size: 9.5px;
  }
  ul {
      padding: 0;
      li {
          padding: 0;
      }
  }
}

@include media-breakpoint-between(md, lg) {
  .bg {
      margin-top: 8rem;
  }
  .title-1{
  font-size: 1.5rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title-h2 {
      font-size: 1.25rem;
      line-height: 55px;
  }
  .card {
   p{
          line-height: auto;
          font-size: 0.95rem;
      }
  }
  .h4-title {
      font-size: 1.25rem;
      text-decoration: underline;
      font-weight: 600;
  }
  .img-fluid {
      width: 70%;
  }
  .h4-orange {
      text-align: center;
  }
  textarea {
      border: 1px solid #d4d4d4;
      outline: none;
      width: 90vw;
  }
  #masInformacion {
      text-align: center;
  }
  #enviarMensaje {
      text-align: center;
      padding-top: 3rem;
  }
  #contacta {
      text-align: center;
  }
  
}

@include media-breakpoint-between(xl, xxl) {
  .bg {
      margin-top: 8rem;
  }
  .title-1{
      font-size: 1.75rem;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title-h2 {
      font-size: 1.75rem;
  }
  .card {
  p {
      line-height: auto;
      font-size: 1.1rem;
      }
  }
  textarea {
      border: 1px solid #d4d4d4;
      outline: none;
      width: 33vw;
  }

  .align {
      text-align: right;
  }

}

</style>
